import { useQuery } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';
import { computed } from 'vue';

import { useNotification, useLoading } from '@/modules/core';

const PAYMENTS_QUERY = gql`
  query payments(
    $completed: Boolean
    $supplierId: ID
    $businessId: ID
    $limit: Int
    $offset: Int
    $isCustomerSide: Boolean
    $excludeInactiveCustomers: Boolean
  ) {
    payments(
      completed: $completed
      supplierId: $supplierId
      businessId: $businessId
      offset: $offset
      limit: $limit
      isCustomerSide: $isCustomerSide
      excludeInactiveCustomers: $excludeInactiveCustomers
    ) {
      totalCount
      nodes {
        id
        supplierId
        businessId
        customerId
        date
        amount
        isRefund
        type
        initialBalance
        createdBy {
          profile {
            firstName
            lastName
          }
        }
        createdAt
        billingLinks {
          amount
          billingId
          billing {
            id
            date
          }
        }
        eventReferences {
          reference
          documentId
        }
        transactions {
          id
          paymentMethod
          directDebit
        }
      }
    }
  }
`;

export const usePayments = (variables, options) => {
  const { error } = useNotification();
  const { result, loading, onError, refetch } = useQuery(PAYMENTS_QUERY, variables, options);
  const paymentConnection = computed(() => result.value?.payments ?? { nodes: [], totalCount: 0 });

  const payments = computed(() => paymentConnection.value.nodes);
  const totalCount = computed(() => paymentConnection.value.totalCount);

  onError((err) => {
    console.error('usePayments', err);
    error();
  });

  return { payments, totalCount, loading, refetch };
};

const PAYMENT_DIFFERENCES_QUERY = gql`
  query paymentDifferences($customerId: ID!) {
    paymentDifferences(customerId: $customerId) {
      supplierPayment {
        id
        customerId
        supplierId
        date
        amount
        isRefund
        eventReferences {
          reference
          documentId
        }
        transactions {
          id
          paymentMethod
        }
      }
      customerPayment {
        type
      }
    }
  }
`;

export const usePaymentDifferences = (variables) => {
  const { error } = useNotification();
  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(PAYMENT_DIFFERENCES_QUERY, variables, () => ({
    enabled: !!variables.value.customerId,
  }));
  const paymentDifferences = computed(() => result.value?.paymentDifferences ?? []);
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('usePaymentDifferences', err);
    error();
  });

  return { paymentDifferences, loading, refetch };
};
